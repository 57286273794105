import { register } from '@netcentric/component-loader';
import { isOnDesktop, removeBodyScroll, addBodyScroll } from 'commons/utils';
import { OPEN_CORP_NAVIGATION, OPEN_CORP_SEARCH } from 'commons/constants/events';
import config from './corp-navigationheader.config';
import * as Events from '../../../../../base/clientlibs/commons/constants/events';

class NavigationHeaderCorp {
  constructor(el, params) {
    this.el = el;
    this.params = params;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.constants = config.constants;
    this.properties = config.properties;
    this.isNavOpen = false;
    this.burgerMenuIsActive = false;
    this.currentLevel = 0;
    this.isSelectingBreadcrumb = false;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEnhancements();
    this.setEventListeners();
    this.setPubSubListeners();
  }

  setRefs() {
    this.navGroupRoot = this.el.querySelector(this.selectors.navCorpGroup);
    this.extraMenus = document.querySelectorAll(this.params.extramenu);
    if (this.extraMenus) {
      this.extraMenuElements = [];
      this.extraMenus.forEach((extraMenu, i) => {
        const extraMenuElement = extraMenu.querySelector(this.selectors.firstLvlItems);
        if (extraMenuElement) {
          // add data-index to extraMenuElement
          extraMenuElement.setAttribute('data-index', i);
          const menuClone = extraMenuElement.cloneNode(true);
          menuClone.classList.add(this.classes.navCorpGroupSecondary);
          this.navGroupRoot.appendChild(menuClone);
        }
        this.extraMenuElements.push(extraMenuElement);
      });
    }

    this.extraLinks = document.querySelector(this.params.extralinks);
    if (this.extraLinks) {
      this.extraLinksElements = this.extraLinks.querySelectorAll(this.selectors.nonImageLinks);
    }
    this.allNavItems = this.el.querySelectorAll(this.selectors.navItem);
    this.menuButton = this.el.querySelector(this.selectors.menuButton);
    this.navCorpRoot = this.el.querySelector(this.selectors.navCorp);
    // Figure out the main content container
    const container = document.querySelector(this.selectors.rootContainer);
    this.mainContent = container.querySelector(this.selectors.mainContent);
    if (!this.params.fullScreen) {
      this.menusHeight = [];
    }
  }

  /**
   * Adds additional markup and behaviour to the navigation items
   */
  setEnhancements() {
    this.allNavItems.forEach(item => {
      const childrenGroup = item.querySelector(this.selectors.navCorpGroup);
      const currentURL = window.location.pathname.replace(/\.[^/.]+$/, '').replace(/\/$/, '');

      if (childrenGroup) {
        item.classList.add(this.classes.navCorpItemHasChildren);
        const link = item.querySelector(this.selectors.navItemLink);
        const newLink = link.cloneNode(true);
        newLink.classList.add(this.classes.clone);
        if (newLink.pathname === currentURL) {
          newLink.classList.add(this.classes.navCorpItemLinkBreadcrumb, this.classes.navCorpItemLinkLastBreadcrumb);
        }

        const subNavMenu = document.createElement('li');
        subNavMenu.classList.add(this.classes.navCorpSubNavMenu);

        const gotoText = document.createElement('span');
        gotoText.classList.add(this.classes.navCorpGoto);
        gotoText.innerText = this.params.goto;

        const backBtn = document.createElement('button');
        backBtn.classList.add(this.classes.navCorpBack);
        backBtn.innerText = this.params.back;

        const childBtn = document.createElement('button');
        childBtn.classList.add(this.classes.arrow, this.classes.arrowRight);
        childBtn.setAttribute('aria-label', this.params.hiddenLabel);
        childBtn.setAttribute('tabindex', '-1');
        link.appendChild(childBtn);

        if (item.classList.contains(this.classes.level0Item)) {
          link.addEventListener('click', e => this.toggleDesktopNavigation(item, e));
        }

        subNavMenu.appendChild(backBtn);
        subNavMenu.appendChild(gotoText);
        subNavMenu.appendChild(newLink);
        childrenGroup.insertBefore(subNavMenu, childrenGroup.firstChild);
      }

      const link = item.querySelector(this.selectors.navItemLink);

      if (currentURL === link.pathname) {
        let parent = item.parentElement.closest(this.selectors.navItem);

        if (parent !== null) {
          const parentURL = parent.querySelector('.cmp-navigationcorp__item-link').pathname;

          if (link.pathname.includes(parentURL)) {
            item.classList.add(this.classes.navCorpItemLinkBreadcrumb);

            while (parent) {
              parent.classList.add(this.classes.navCorpItemLinkBreadcrumb);
              if (parent.parentElement) {
                parent = parent.parentElement.closest(this.selectors.navItem);
              }
            }
          }
        } else {
          item.classList.add(this.classes.navCorpItemLinkBreadcrumb);
        }
      }
    });

    if (this.extraMenuElements) {
      // Check if the extra menu has children
      this.extraMenuElementChildren = [];

      this.extraMenuElements.forEach(extraMenuElement => {
        const extraMenuElementChildren = extraMenuElement.querySelector('.cmp-navigationcorp__group');

        if (extraMenuElementChildren) {
          const childBtn = document.createElement('button');
          const link = extraMenuElement.querySelector(this.selectors.navItemLink);
          childBtn.classList.add(this.classes.arrow, this.classes.arrowRight);
          childBtn.setAttribute('aria-label', this.params.hiddenLabel);
          childBtn.setAttribute('tabindex', '-1');
          link.appendChild(childBtn);
          this.extraMenuElementChildren.push(extraMenuElementChildren);
        }
      });
    }

    if (this.extraLinksElements) {
      this.extraLinksElements.forEach(link => {
        const li = document.createElement('li');
        li.setAttribute('itemprop', 'name');
        li.className = `${this.classes.navCorpItem} ${this.classes.level0Item} ${this.classes.navCorpGroupLink}`;

        const a = link.cloneNode(true);
        a.className = this.classes.navCorpItemLink;

        li.appendChild(a);
        this.navGroupRoot.appendChild(li);
      });
    }
  }

  setEventListeners() {
    this.menuButton.addEventListener('click', event => this.onMenuClick(event));
    this.navCorpRoot.addEventListener('click', e => this.toggleMobileMenus(e));

    if (this.extraMenuElementChildren.length) {
      this.extraMenuElements.forEach((extraMenuElement, i) => {
        if (extraMenuElement.querySelector(this.selectors.navCorpGroup)) {
          extraMenuElement?.addEventListener('click', event => {
            event.preventDefault();
            const extraMenuItem = this.el.querySelectorAll(`.${this.classes.navCorpGroupSecondary}`);
            // See event listeners on extraMenuItem
            this.toggleDesktopNavigation(extraMenuItem[i], event);
          });
        }
      });
    }

    window.PubSub.subscribe(OPEN_CORP_SEARCH, () => {
      if (this.isNavOpen) {
        if (isOnDesktop()) {
          this.closeDesktopMenu();
        } else {
          this.closeMobileMenu();
        }
        addBodyScroll(this.classes.noScroll); // To solve async issue between search and navigation
        addBodyScroll(this.classes.navCorpMenuopen);
      }
    });
  }

  setPubSubListeners() {
    window.PubSub.subscribe(Events.CLOSE_NAVIGATION, () => {
      if (this.isNavOpen) {
        if (isOnDesktop()) {
          this.closeDesktopMenu();
        } else {
          this.closeMobileMenu();
        }
      }
    });
  }

  // Mobile only =================================

  onMenuClick(event) {
    event.preventDefault();
    const menuIsOpen = this.menuButton.classList.contains(this.classes.open);

    if (menuIsOpen) {
      this.closeMobileMenu();
    } else {
      this.openMobileMenu();
    }
  }

  removeAllActiveElements() {
    const elemClassNames = [this.classes.navCorpItemActive, this.classes.navCorpGroupActive]
    elemClassNames.forEach(elemClassName => {
      const activeItems = this.el.querySelectorAll(`.${elemClassName}`);
      activeItems.forEach(item => {
        item.classList.remove(elemClassName);
      });
    });
  }

  closeMobileMenu() {
    this.menuButton.classList.remove(this.classes.open);
    this.isNavOpen = false;

    removeBodyScroll(this.classes.noScroll);
    removeBodyScroll(this.classes.navCorpMenuopen);
    this.navGroupRoot.classList.remove(this.classes.navCorpGroupActive);
    this.navCorpRoot.classList.remove(this.classes.navCorpActive);

    this.currentLevel = 0;
    this.removeAllActiveElements();
    this.navCorpRoot.style.setProperty(this.properties.currentLevel, this.currentLevel);
    this.navCorpRoot.classList.remove(this.classes.navCorpSubMenuOpen);
  }

  openMobileMenu() {
    window.PubSub.publish(OPEN_CORP_NAVIGATION);

    this.menuButton.classList.add(this.classes.open);
    this.isNavOpen = true;

    addBodyScroll(this.classes.noScroll);
    addBodyScroll(this.classes.navCorpMenuopen);
    this.navGroupRoot.classList.add(this.classes.navCorpGroupActive);
    this.navCorpRoot.classList.add(this.classes.navCorpActive);

    // Scroll to currently selected link
    const breacrumbItemList = this.navGroupRoot.querySelectorAll(this.selectors.navCorpItemLinkBreadcrumb);
    breacrumbItemList.forEach((item, index, array) => {
      if (index < array.length - 1){
        this.navigateOneLevelin(item);
      }
    });
  }

  navigateOneLevelin(item){
    this.currentLevel +=1;
    this.toggleItemAndGroup(item);
    this.navCorpRoot.style.setProperty(this.properties.currentLevel, this.currentLevel);
    this.navCorpRoot.classList.add(this.classes.navCorpSubMenuOpen);
  }

  toggleItemAndGroup(item){
    item.classList.toggle(this.classes.navCorpItemActive);
    const group = item.querySelector(this.selectors.navCorpGroup);
    group.classList.toggle(this.classes.navCorpGroupActive);
  }

  toggleMobileMenus(event) {
    if (!isOnDesktop()) {
      const item = event.target.closest(this.selectors.navItem);

      if (item) {
        if (event.target.classList.contains(this.classes.navCorpBack)) {
          event.preventDefault();
          this.currentLevel -=1;
          this.toggleItemAndGroup(item);
          this.navCorpRoot.style.setProperty(this.properties.currentLevel, this.currentLevel);
          if (this.currentLevel === 0) {
            this.navCorpRoot.classList.remove(this.classes.navCorpSubMenuOpen);
          }
        } else if (!item.classList.contains(this.classes.navCorpItemActive) &&
          item.classList.contains(this.classes.navCorpItemHasChildren)) {
          event.preventDefault();
          this.navCorpRoot.scrollTop = 0;
          this.navigateOneLevelin(item);
        }
      }
    }
  }

  // Desktop only =================================

  selectBreadcrumbElement() {
    // Scroll to currently selected link
    let lastMenu = this.menuContainer.querySelectorAll(this.selectors.navCorpMenu);
    lastMenu =  lastMenu[lastMenu.length - 1];
    const breadcrumbItems = lastMenu.querySelectorAll(this.selectors.navCorpItemLinkBreadcrumb);
    let breadcrumbItem = breadcrumbItems[0];

    let i = 1;
    while (breadcrumbItem && !breadcrumbItem.classList.contains(this.classes.navCorpItemLinkLastBreadcrumb)) {
      breadcrumbItem.classList.add(this.classes.navCorpItemActive);
      if (i === breadcrumbItems.length) {
        break;
      }
      // Open the sub menus
      this.addSubMenu(breadcrumbItem);
      lastMenu = this.menuContainer.querySelectorAll(this.selectors.navCorpMenu);
      lastMenu =  lastMenu[lastMenu.length - 1];
      breadcrumbItem = lastMenu.querySelector(this.selectors.navCorpItemLinkBreadcrumb);
      i++;
    }
    this.isSelectingBreadcrumb = false;
  }

  toggleDesktopNavigation(item, event) {
    if (isOnDesktop()) {
      event.preventDefault();
      this.isNavOpen = !this.isNavOpen;
      this.isSelectingBreadcrumb = true;
      if (!this.params.fullScreen) {
        this.menusHeight = [];
      }

      if (this.isNavOpen) {
        window.PubSub.publish(OPEN_CORP_NAVIGATION);

        addBodyScroll(this.classes.noScroll);
        addBodyScroll(this.classes.navCorpMenuopen);
        this.createMenuContainerWithImage(item.querySelector(this.selectors.navItemBG), this.mainContent);
        const group = item.querySelector(this.selectors.navCorpGroup);
        this.currentLevel = 1;
        this.addMenu(group);

        if (item.classList.contains(this.classes.navCorpGroupSecondary)) {
          this.extraMenuElements.forEach((extraMenuElement) => {
            if (extraMenuElement.dataset.index === item.dataset.index) {
              extraMenuElement.classList.add(this.classes.open);
            }
          });
        }
        item.classList.add(this.classes.open);

        this.selectBreadcrumbElement();
      } else if (!item.classList.contains(this.classes.open)) {
        // Open another menu while the current one is open
        this.isNavOpen = true;
        this.updateMenuContainerImage(item.querySelector(this.selectors.navItemBG));

        const menus = this.menuContainer.querySelectorAll(this.selectors.navCorpMenu);
        menus.forEach(menu => {
          this.menuContainer.removeChild(menu);
        });
        this.resetMenuContainerStyles();

        const group = item.querySelector(this.selectors.navCorpGroup);
        this.currentLevel = 1;
        this.addMenu(group);
        this.el.querySelectorAll(`.${this.classes.open}`).forEach(openItem => {
          openItem.classList.remove(this.classes.open);
        });
        this.extraMenuElements.forEach((extraMenuElement) => {
          extraMenuElement.classList.remove(this.classes.open);
        });
        if (item.classList.contains(this.classes.navCorpGroupSecondary)) {
          this.extraMenuElements.forEach((extraMenuElement) => {
            if (extraMenuElement.dataset.index === item.dataset.index) {
              extraMenuElement.classList.add(this.classes.open);
            }
          });
        }
        item.classList.add(this.classes.open);

        this.selectBreadcrumbElement();
      } else {
        this.closeDesktopMenu();
      }
      this.adjustMenuContainerHeight();
    }
  }

  closeDesktopMenu() {
    removeBodyScroll(this.classes.noScroll);
    removeBodyScroll(this.classes.navCorpMenuopen);
    this.mainContent.removeChild(this.menuContainer);
    this.currentLevel = 0;
    const item = this.el.querySelector(`${this.selectors.navItem}.${this.classes.open}`);
    this.extraMenuElements.forEach((extraMenuElement) => {
      extraMenuElement.classList.remove(this.classes.open);
    });
    item.classList.remove(this.classes.open);
    this.isNavOpen = false;
  }

  createMenuContainerWithImage(image, container) {
    this.menuContainer = document.createElement('div');
    this.menuContainer.classList.add(this.classes.navCorpMenuContainer);
    container.appendChild(this.menuContainer);

    if (image) {
      const imageClone = image.cloneNode(true);
      imageClone.removeAttribute('style');
      this.menuContainer.appendChild(imageClone);
    }

    const backBtn = document.createElement('button');
    backBtn.classList.add(this.classes.navCorpClose);
    this.menuContainer.appendChild(backBtn);

    backBtn.addEventListener('click', () => {
      this.isNavOpen = false;
      this.closeDesktopMenu();
    });
    this.menuContainer.addEventListener('click', e => this.toggleMenu(e));
  }

  updateMenuContainerImage(image) {
    if (image) {
      let imageClone = this.menuContainer.querySelector(this.selectors.navItemBG);
      if (!imageClone) {
        this.mainContent.removeChild(this.menuContainer);
        this.createMenuContainerWithImage(image, this.mainContent);
        imageClone = this.menuContainer.querySelector(this.selectors.navItemBG);
      }

      imageClone.src = image.src;
      imageClone.alt = image.alt;
    } else {
      const imageClone = this.menuContainer.querySelector(this.selectors.navItemBG);
      imageClone?.remove();
    }
  }

  toggleMenu(event) {
    const item = event.target.closest(this.selectors.navItem);

    if (item) {
      if (item.classList.contains(this.classes.navCorpItemActive)) {
        event.preventDefault();
        // Close the sub menus
        item.classList.remove(this.classes.navCorpItemActive);
        const groupLevel = this.extractLevelX(item.className);
        this.closeSubmenus(groupLevel);
      } else if (item.classList.contains(this.classes.navCorpItemHasChildren)) {
        event.preventDefault();
        // Open the sub menus
        const siblings = item.parentNode.querySelectorAll(this.selectors.navItemActive);
        siblings.forEach(sibling => {
          sibling.classList.remove(this.classes.navCorpItemActive);
        });
        item.classList.add(this.classes.navCorpItemActive);
        this.addSubMenu(item);
      }
      this.adjustMenuContainerHeight();
    }
  }

  addMenu(group) {
    const menu = document.createElement('div');
    menu.classList.add(this.classes.navCorpMenu);
    this.menuContainer.appendChild(menu);

    const menuClone = group.cloneNode(true)
    menuClone.classList.add(this.classes.navCorpGroupActive);
    menu.appendChild(menuClone);

    this.menusHeight?.push(menuClone.querySelectorAll(this.selectors.navItemChild).length);
    if(!this.isSelectingBreadcrumb) {
      const lastBreadcrumb = menuClone.querySelector(this.selectors.navCorpItemLinkLastBreadcrumb);
      if (lastBreadcrumb) {
        lastBreadcrumb.classList.remove(this.classes.navCorpItemLinkLastBreadcrumb);
      }
    }
  }

  addSubMenu(group) {
    const groupLevel = this.extractLevelX(group.className);

    if (groupLevel < this.currentLevel) {
      this.closeSubmenus(groupLevel);
    }
    const newGroup = group.querySelector(this.selectors.navCorpGroup);
    this.addMenu(newGroup);
    this.currentLevel += 1;

    if (this.currentLevel > 3) {
      this.adjustMenuContainerWidth();
    }
  }

  closeSubmenus(groupLevel) {
    while ((this.currentLevel - groupLevel) > 0) {
      this.menuContainer.removeChild(this.menuContainer.lastChild);
      this.menusHeight?.pop();
      this.currentLevel -= 1;
    }

    if (this.currentLevel < 4) {
      this.resetMenuContainerStyles();
      const menus = this.menuContainer.querySelectorAll(this.selectors.navCorpMenu);
      menus.forEach(menu => {
        menu.removeAttribute('style');
      });
    } else {
      this.adjustMenuContainerWidth();
    }
  }

  adjustMenuContainerWidth() {
    const val = (this.currentLevel - 3) * 33.33;
    this.menuContainer.style.width = `${100 + val}vw`;
    this.menuContainer.style.marginInlineStart = `-${val}vw`;
  }


  adjustMenuContainerHeight() {
    if (!this.params.fullScreen) {
      if (!this.menuContainerBaseHeight) {
        this.menuContainerBaseHeight = this.menuContainer.offsetHeight;
      }
      const menuMaxHeight = document.body.offsetHeight - this.constants.menuHeight;
      const maxHeight = Math.max(...this.menusHeight);
      const minHeight = 234 + maxHeight * 46;

      if (minHeight > this.menuContainer.offsetHeight) {
        if (minHeight > menuMaxHeight) {
          this.menuContainer.style.height = `calc(100vh - ${this.constants.menuHeight}px)`;
        } else  {
          this.menuContainer.style.height = `${minHeight}px`;
        }
      } else if (this.menuContainer.style.height !== '') {
        if (minHeight > this.menuContainerBaseHeight) {
          this.menuContainer.style.height = `${minHeight}px`;
        } else {
          this.menuContainer.style.height = `${this.menuContainerBaseHeight}px`;
        }
      }
    }
  }

  extractLevelX(str) {
    const regex = /cmp-navigationcorp__item--level-(\d+)/;
    const match = regex.exec(str);
    if (match) {
      const x = parseInt(match[1], 10);
      return x;
    }
    return null;
  }

  resetMenuContainerStyles() {
      this.menuContainer.style.width = '';
      this.menuContainer.style.marginInlineStart = '';
  }
}

register({ NavigationHeaderCorp });
