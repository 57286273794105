export default {
  selectors: {
    firstLvlItems: '.cmp-navigationcorp > .cmp-navigationcorp__group > .cmp-navigationcorp__item',
    mainContent: ':scope > div:not(.experiencefragment):not(.batcom-title)',
    menuButton: '.batcom-menuButton__base',
    navCorp: '.cmp-navigationcorp',
    navCorpGroup: '.cmp-navigationcorp__group',
    navCorpMenu: '.cmp-navigationcorp__menu',
    navCorpItemLinkBreadcrumb: '.cmp-navigationcorp__item-link-breadcrumb',
    navCorpItemLinkLastBreadcrumb: '.cmp-navigationcorp__item-link-breadcrumb--last',
    navItem: '.cmp-navigationcorp__item',
    navItemChild: ':scope > .cmp-navigationcorp__item',
    navItemLink: '.cmp-navigationcorp__item-link',
    navItemActive: '.cmp-navigationcorp__item--active',
    navItemBG: '.cmp-navigationcorp__item-bg',
    nonImageLinks: '.batcom-header__link:not(.batcom-languagenavigation) a:not(.batcom-header__link-img a)',
    rootContainer: '.root .cmp-container',
  },
  classes: {
    arrow: 'batcom-navigationcorp__arrow',
    arrowRight: 'batcom-navigationcorp__arrow--right',
    clone: 'clone',
    navCorpActive: 'cmp-navigationcorp--active',
    navCorpBack: 'cmp-navigationcorp__back',
    navCorpClose: 'cmp-navigationcorp__close',
    navCorpGoto: 'cmp-navigationcorp__goto',
    navCorpGroupActive: 'cmp-navigationcorp__group--active',
    navCorpGroupLink: 'cmp-navigationcorp__group--link',
    navCorpGroupSecondary: 'cmp-navigationcorp__group--secondary',
    navCorpItem: 'cmp-navigationcorp__item',
    navCorpItemActive: 'cmp-navigationcorp__item--active',
    navCorpItemHasChildren: 'cmp-navigationcorp__item--has-children',
    navCorpItemLink: 'cmp-navigationcorp__item-link',
    navCorpItemLinkBreadcrumb: 'cmp-navigationcorp__item-link-breadcrumb',
    navCorpItemLinkLastBreadcrumb: 'cmp-navigationcorp__item-link-breadcrumb--last',
    navCorpMenu: 'cmp-navigationcorp__menu',
    navCorpMenuContainer: 'cmp-navigationcorp__menu-container',
    navCorpSubMenuOpen: 'cmp-navigationcorp--sub-menu-open',
    navCorpSubNavMenu: 'cmp-navigationcorp__subnavmenu',
    level0Item: 'cmp-navigationcorp__item--level-0',
    navCorpMenuopen: 'navCorp-menu-open',
    noScroll: 'noScroll',
    open: 'open',
  },
  properties: {
    currentLevel: '--batcom-navigation-current-level',
  },
  constants: {
    menuHeight: 128,
  },
};
